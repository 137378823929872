import React, { useContext } from "react";
import { OverlayContext } from "./Overlay";
import { OverlayType } from "./Context";
import PrescriptionDosageCart from "./PrescriptionDosage/PrescriptionDosageCart";
import PrescriptionSliderCart from "./PrescriptionSlider/PrescriptionSliderCart";
import ModalComponent from "./ModalWindow/ModalComponent";
import ClinicalFindings from "./ClinicalFindings/ClinicalFindings";

const OverlayManager = () => {
  const overlay = useContext(OverlayContext);
  switch (overlay?.type) {
    case OverlayType.PrescriptionDosage:
      return <PrescriptionDosageCart overlay={overlay} />;
    case OverlayType.PrescriptionSlider:
      return <PrescriptionSliderCart 
      overlay={overlay}
      autoSave={true}
    />;
    case OverlayType.ModalWindow:
      return <ModalComponent overlay={overlay} />;
    case OverlayType.ClinicalFindings:
      return <ClinicalFindings overlay={overlay} />;
    default:
      return <></>;
  }
};

export default OverlayManager;
