import React, { useState, useContext, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { OverlayContext } from "../Overlay";
import IncrementDecrement from "../../Tools/IncrementDecrement";
import CloseIcon from "@mui/icons-material/Close";
import PrescriptionEditorNew from "../../components/PrescriptionEditorNew";
import DownArrowIcon from "../../static/images/down_arrow.svg";
import { dose_unit, fetchDosage } from "../../static/staticData/staticData";
import { dose_admin_method } from "../../static/staticData/staticData";
import { dose_timings } from "../../static/staticData/staticData";
import { dietary_instructions } from "../../static/staticData/staticData";
import { TextField } from "@material-ui/core";
import Button from "../../Tools/Button";
import { PatientpageContext } from "../../context/GlobalContext";
import { FormHelperText } from "@material-ui/core";
import { ServiceCall } from "../../utility/service-call";
import { Ls, renameObjectKey } from "../../utility/utility";

const PrescriptionDosageCart = ({ overlay }) => {
  const defaultError = {
    dose_qty: {
      hasError: false,
      errorMsg: "",
    },
    // dose_unit: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    // dose_admin_method: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    // dose_timings: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    // dietary_instructions: {
    //   hasError: false,
    //   errorMsg: "",
    // },
  };

  let contextData = overlay.context?.medicationData;
  const [count, setCount] = useState(Number(contextData?.dose_qty) || 1);
  const patientContext = useContext(PatientpageContext);

  const listMedications = patientContext?.listMedications


  const PrefetchData = async () => {
    try {
      let id = `?drug_name=${contextData?.medication}&drug_type=${contextData?.medication_form}`;
      let fetchData = await ServiceCall.getnewv2(
        "/v2/doctor/dosage/pattern",
        id,
        {
          "Content-Type": "application/json",
          doc_key: Ls.get("docKey"),
          doc_secret: Ls.get("docToken"),
        }
      );
      if (fetchData) {
        let newObj = fetchData?.data;
        Object.keys(newObj).forEach((value) => {
          if (!!newObj[value]) {
            setFormData({
              ...formData,
              [value]: newObj[value],
            });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const defaultData = {
    dose_qty: contextData?.dose_qty || 1,
    dose_unit: contextData?.dose_unit || "Tablet",
    dose_admin_method: contextData?.dose_admin_method || "P.O.(Orally)",
    dose_timings: contextData?.dose_timings || "od (One time in a day)",
    dietary_instructions: contextData?.dietary_instructions || "a.c. (Before Meals)",
    other_instructions: contextData?.other_instructions || "",
  };
  const [open, setOpen] = useState(true);
  const [formError, setFormError] = useState(defaultError);
  const [formData, setFormData] = useState(defaultData);
  const handleClose = () => setOpen(false);
  const [text, setText] = React.useState("");
  const overlaycontext = useContext(OverlayContext);

  const IncNum = () => {
    let countInc = count === "NA" ? 0 : count < 50 ? count + 0.5 : count;
    setCount(countInc);
    errorHandling(countInc, "dose_qty");
    setFormData({
      ...formData,
      dose_qty: countInc,
    });
  };

  const inputNum = (e) => {
    const value = e.target.value;
    setCount(value);
    errorHandling(value, "dose_qty");
    setFormData({
      ...formData,
      dose_qty: value,
    });
  };

  const DecNum = () => {
    let countDec = count > 0 ? count - 0.5 : "NA";
    setCount(countDec);
    errorHandling(countDec, "dose_qty");
    setFormData({
      ...formData,
      dose_qty: countDec,
    });
  };

  const errorHandling = (value, key) => {
    let errorFeild = formError[key];
    if (value) {
      errorFeild.hasError = false;
      errorFeild.errorMsg = "";
    } else {
      errorFeild.hasError = true;
      errorFeild.errorMsg = `${key} cannot be empty`;
    }
    if (key === "dose_qty" && value > 50) {
      errorFeild.hasError = true;
      errorFeild.errorMsg = `${key} cannot be more than 50`;
    }

    setFormError({ ...formError, [key]: errorFeild });
  };

  const handleChange = (value, key) => {
    // errorHandling(value, key);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleDescription = (e) => {
    let name = e.target.name;
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    let formErrorbolean = false;
    Object.keys(formError).map((item) => {
      errorHandling(formData[item], item);
    });

    Object.keys(formError).map((item) => {
      if (formError[item].hasError) {
        formErrorbolean = true;
      }
    });

    if (!formErrorbolean) {
      let durationFormate =
        formData?.dose_admin_method +
        " - " +
        formData?.dose_qty +
        formData?.dose_unit +
        " - " +
        formData?.dose_timings +
        "....";
      overlay.context.setDosage(durationFormate);
      overlay.context.handleChangePrescription(formData, formError);
      overlaycontext.hide();
      handleClose();
    }
  };


  
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="dosage"
      >
        <div className="dosage__container">
          <div
            className="dosage__close"
            onClick={() => {
              handleClose();
              overlaycontext.hide();
            }}
          >
            <CloseIcon />
          </div>
          <h1 className="dosage__heading">
            Dosage for {contextData?.medication} ({contextData?.medication_form}
            ):
          </h1>
          <div className="dosage__box">
            <div className="dosage__box__feilds">
              <div className="dosage__box__feilds__title">Quantity</div>
              <div className="dosage__box__feilds__box">
                <div className="dosage__box__feilds__box__dail">
                  <IncrementDecrement
                    IncNum={IncNum}
                    DecNum={DecNum}
                    inputNum={inputNum}
                    count={count}
                    containerClass="plusminus"
                  />
                </div>
                <div className="dosage__box__feilds__box__prescription">
                  <PrescriptionEditorNew
                    label="dose_unit"
                    placeholder="Type"
                    icon={DownArrowIcon}
                    data={listMedications?.dose_unit}
                    feildsReset={formData}
                    changeHandler={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* {formError?.dose_unit?.hasError && (
              <FormHelperText className="validation-error">
                {formError?.dose_unit?.errorMsg}
              </FormHelperText>
            )} */}
            {formError?.dose_qty?.hasError && (
              <FormHelperText className="validation-error">
                {formError?.dose_qty?.errorMsg}
              </FormHelperText>
            )}
            <div className="dosage__box__feilds">
              {" "}
              <div className="dosage__box__feilds__title">How</div>
              <div className="dosage__box__feilds__box">
                <PrescriptionEditorNew
                  label="dose_admin_method"
                  placeholder="How"
                  icon={DownArrowIcon}
                  data={listMedications?.dose_admin_method}
                  feildsReset={formData}
                  changeHandler={handleChange}
                />
              </div>
            </div>
            {/* {formError?.dose_admin_method.hasError && (
              <FormHelperText className="validation-error">
                {formError.dose_admin_method.errorMsg}
              </FormHelperText>
            )} */}
            <div className="dosage__box__feilds">
              {" "}
              <div className="dosage__box__feilds__title">Timings</div>
              <div className="dosage__box__feilds__box">
                <PrescriptionEditorNew
                  fetchType="dose_timings"
                  label="dose_timings"
                  placeholder="Timings"
                  icon={DownArrowIcon}
                  data={listMedications?.dose_timings}
                  feildsReset={formData}
                  changeHandler={handleChange}
                />
              </div>
            </div>
            {/* {formError?.dose_timings.hasError && (
              <FormHelperText className="validation-error">
                {formError.dose_timings.errorMsg}
              </FormHelperText>
            )} */}
            <div className="dosage__box__feilds">
              {" "}
              <div className="dosage__box__feilds__title">Instruction</div>
              <div className="dosage__box__feilds__box">
                <PrescriptionEditorNew
                  label="dietary_instructions"
                  placeholder="Instruction"
                  icon={DownArrowIcon}
                  data={listMedications?.dietary_instructions}
                  feildsReset={formData}
                  changeHandler={handleChange}
                />
              </div>
            </div>
            {/* {formError?.dietary_instructions.hasError && (
              <FormHelperText className="validation-error">
                {formError.dietary_instructions.errorMsg}
              </FormHelperText>
            )} */}
            <div className="dosage__box__textarea">
              <textarea
                id="story"
                name="other_instructions"
                value={formData?.other_instructions}
                rows="7"
                cols="33"
                placeholder="Other Instruction"
                onChange={handleDescription}
              />
            </div>
            <div className="dosage__box__button">
              <Button text="SAVE" handleClick={handleSubmit} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrescriptionDosageCart;
