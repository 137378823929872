import React, { useRef, useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "@mui/material/Slider";
import Button from "../../Tools/Button";

const PrescriptionSliderCart = ({ overlay, autoSave = false }) => {
  const [open, setOpen] = useState(true);
  const [range, setRange] = useState("");
  const duration = useRef(2);
  const handleClose = () => setOpen(false);
  let contextData = overlay.context?.medicationData;

  

  useEffect(() => {
    // Ensure auto-save works even if modal is not opened
    if (autoSave && contextData) {
      const defaultDuration = contextData?.duration || 2;
      duration.current = defaultDuration;
      setRange(defaultDuration);
      
      // Directly call save logic
      overlay.context.setDuration(`${defaultDuration} Days`);
      overlay.context.handleChangePrescription(defaultDuration, "duration");
      overlay.hide();
    }
  }, [autoSave, contextData]);


  function valuetext(value) {
    duration.current = value;
    setRange(value);
  }

  const handleSubmit = () => {
    if (!!range) {
      overlay.context.setDuration(`${range} Days`);
      overlay.context.handleChangePrescription(range, "duration");
      overlay.hide();
    }
  };

    // Only render modal if not auto-saving
    if (autoSave && contextData) {
      return null;
    }

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="dosage"
    >
      <div className="dosage__container duration__container">
        <div
          className="dosage__close"
          onClick={() => {
            handleClose();
            overlay.hide();
          }}
        >
          <CloseIcon />
        </div>
        <div className="duration__heading">
          Dosage for {contextData?.medication} ({contextData?.medication_form}
          ):
        </div>
        <div className="duration__currentValue">{duration?.current} Days</div>
        <div className="duration__slider">
          <Slider
            aria-label="Duration"
            defaultValue={overlay.context?.medicationData?.duration || 2}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={30}
          />
        </div>
        <div className="dosage__box__button">
          <Button text="SAVE" handleClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default PrescriptionSliderCart;
